import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Modal from 'react-modal';
import './PortfolioPage.css'; // Import Bootstrap and your CSS file for styling

// GraphQL query to fetch portfolio items with featuredImage sourceUrl
const GET_PORTFOLIO_ITEMS = gql`
  {
    portfolioItems {
      edges {
        node {
          id
          title
          content
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

const PortfolioPage = () => {
  const { loading, error, data } = useQuery(GET_PORTFOLIO_ITEMS);
  const [selectedImage, setSelectedImage] = useState(null);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const portfolioItems = data.portfolioItems.edges.map(edge => edge.node);

  const openModal = imageUrl => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
    <div className="container-fluid p-0">
      <div className="page-heading">
        <h1>Portfolio</h1>
      </div>
    </div>
    <div className="container">
      <div className="row">
        {portfolioItems.map(item => (
          <div className="col-md-4 mb-4" key={item.id}>
            <div className="portfolio-item">
              {item.featuredImage && (
                <img
                  src={item.featuredImage.node.sourceUrl}
                  alt={item.title}
                  className="img-thumbnail"
                  onClick={() => openModal(item.featuredImage.node.sourceUrl)}
                />
              )}
              <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={selectedImage !== null}
        onRequestClose={closeModal}
        contentLabel="Full-size Image"
      >
        {selectedImage && (
          <img src={selectedImage} alt="Full-size" className="full-size-image" />
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
    </div>
  );
};

export default PortfolioPage;
