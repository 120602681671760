// ServicePage.js

import React from 'react';
import './ServicesPage.css'; // Import Bootstrap and your CSS file for styling (create this file)

const ServicePage = () => {
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="page-heading">
          <h1>Services</h1>
        </div>
      </div>
      <div className="container service-page">
      <section className="service-section">
        <div className="row">
          <div className="col-md-6">
            <div className="service-content">
              <h2>Custom Creative Design Layouts</h2>
              <p>I specialize in crafting bespoke designs that fuse aesthetic appeal with functionality. My approach revolves around understanding your brand identity and translating it into visually captivating and intuitive designs.

What I Offer:
Customized Design Solutions
I delve deep into understanding your brand ethos and target audience, creating custom design solutions tailored to resonate with your audience and elevate your brand's identity.

User-Centric Layouts
My design process prioritizes user experience, ensuring intuitive layouts that guide visitors seamlessly through your website and encourage interaction.<br/><br/>

High-Quality Visuals and Graphics
Leveraging industry-standard tools like Photoshop, Adobe XD, and Figma, I create high-quality visuals and graphics that enhance your brand's narrative and leave a lasting impression.

Iterative Design Process
Collaboration is at the core of my design process. I involve you throughout the iterative stages, incorporating feedback and revisions to ensure the final design exceeds your expectations.

Responsive and Mobile-Optimized Designs
With a focus on responsiveness, I ensure your designs adapt flawlessly across various devices, providing a consistent and engaging user experience.</p>
            </div>
          </div>
          <div className="col-md-6 order-md-1">
            <div className="service-image">
              <img src="/images/layout_design.png" alt="Design" />
            </div>
          </div>
        </div>
      </section>

      <section className="service-section">
        <div className="row">
          <div className="col-md-6 order-md-2">
            <div className="service-content">
              <h2>Website Development</h2>
              <p>Being proficient in HTML5/CSS3, WordPress, WooCommerce, Shopify, and Squarespace, I offer comprehensive website development services that transform designs into fully functional online platforms.

What I Provide:
WordPress & E-commerce Development
Leveraging WordPress and WooCommerce, I create dynamic and user-friendly websites, ensuring seamless navigation and content management for your business.

Shopify and Squarespace Solutions
For businesses seeking tailored e-commerce experiences, I develop Shopify and Squarespace websites that are visually appealing, easy to manage, and optimized for conversions.<br/><br/>

Seamless Integration of Features
I seamlessly integrate features like contact forms, galleries, blogs, and other functionalities into your website, enhancing its usability and engagement potential.

User-Friendly Content Management Systems
Empowering you with easy-to-use content management systems, I ensure you have control over your website's content, allowing effortless updates and modifications.</p>
            </div>
          </div>
          <div className="col-md-6 order-md-1">
            <div className="service-image">
              <img src="/images/development-2.png" alt="Development" />
            </div>
          </div>
        </div>
      </section>

      <section className="service-section">
        <div className="row">
          <div className="col-md-6">
            <div className="service-content">
              <h2>Web Applications Development</h2>
              <p>My expertise extends to building robust and responsive React applications, offering customized solutions that cater to your specific business requirements.

What Sets Me Apart:
Custom Web Applications
I craft scalable and efficient web applications using React and Node.js, delivering tailored solutions that align with your business objectives.

JavaScript/Node.js Single-Page Applications
My proficiency in JavaScript and Node.js allows me to develop dynamic single-page applications (SPAs) that provide an interactive and seamless user experience.<br/><br/>

Collaborative Development Approach
Throughout the development phase, I maintain open communication, ensuring your vision is translated into a functional and aesthetically pleasing application.

Iterative Prototyping and Testing
I emphasize iterative prototyping and rigorous testing to deliver applications that are intuitive, secure, and optimized for performance.</p>
            </div>
          </div>
          <div className="col-md-6 order-md-2">
            <div className="service-image">
              <img src="/images/webapp.png" alt="App" />
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default ServicePage;
