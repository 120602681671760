import React from 'react';
import './About.css'; // Import Bootstrap and your CSS file for styling

const AboutPage = () => {

  return (
    <div className='about'>
    <div className="container-fluid p-0">
      <div className="page-heading">
        <h1>About CreatiDev</h1>
      </div>
    </div>
    <div className="container">
      <div className="row">
      <div className="col-md-12">
        <p>
Welcome to CreatiDev, your gateway to innovative digital solutions! I'm Boobalan, the creative mind behind this endeavor. As a passionate solo designer and developer, I founded CreatiDev with a vision to blend artistry with technology, offering tailored solutions that redefine digital experiences.
<br/><br/>
<b>My Journey & Expertise</b><br/>
With a foundation in design, layout, and coding, I embarked on a journey driven by a deep-seated passion for crafting visually captivating and functionally rich digital solutions. My expertise spans across a diverse spectrum, encompassing WordPress & WooCommerce development, Shopify and Squarespace customization, and React-based applications.
<br/><br/>
<b>
Commitment to Excellence</b><br/>
At CreatiDev, my commitment goes beyond delivering mere services; it's about collaborating with you to transform visions into realities. Whether it's designing an eye-catching website, developing an intuitive application, or enhancing your brand's online presence, my mission is to exceed your expectations.
<br/><br/>
<b>
Collaborative Approach</b><br/>
I believe in a collaborative approach to every project. Your insights and aspirations are invaluable. I work closely with clients, ensuring that every step aligns with your vision. This collaborative spirit fosters an environment of open communication, creativity, and a shared commitment to achieving exceptional results.
<br/><br/>
<b>
Why Choose CreatiDev?</b><br/>
Personalized Attention: As a solo designer, I provide dedicated attention to each project, ensuring a personalized touch and meticulous craftsmanship.<br/>
Innovation & Adaptability: I stay updated with the latest trends and technologies, empowering your digital presence with cutting-edge solutions.<br/>
Results-Driven Solutions: My focus is on delivering not just a product but a solution that drives results and elevates your brand.<br/><br/>
Thank you for considering CreatiDev for your digital needs. Let's embark on this creative journey together and bring your digital aspirations to life.</p>
      </div>
      </div>
      
    </div>
    </div>
  );
};

export default AboutPage;
