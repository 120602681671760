import React from 'react';
import './Pricing.css'; // Import Bootstrap and your CSS file for styling (create this file)
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Pricing = () => {
  return (
    <section className="pricing">
      <div className="container">
        <h2>Service Packages & Pricing</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Basic Website</h5>
                <p className="card-text">$1500</p>
                <ul className="features-list">
                  <li>Custom Design & Layout</li>
                  <li>Unlimited Design Revision Cycle</li>
                  <li>Responsive HTML5/CSS3 Coding</li>
                  <li>Up to 5 Pages</li>
                  <li>Basic Contact Form</li>
                  <li>Integration of Stock Images</li>
                  <li>Standard SEO Optimization</li>
                  <li>Free Support</li>
                </ul>
                <Link className="btn btn-primary" to="/contact">Get Started</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card highlight">
              <div className="card-body">
                <h5 className="card-title">Multi-Pages Website</h5>
                <p className="card-text">$2000</p>
                <ul className="features-list">
                  <li>Custom Design & Layout</li>
                  <li>Unlimited Design Revision Cycle</li>
                  <li>Responsive HTML5/CSS3 Coding</li>
                  <li>Up to 20 Pages</li>
                  <li>Contact Forms and Basic User Interactivity</li>
                  <li>Gallery/Portfolio Showcase</li>
                  <li>Blog/News Section</li>
                  <li>Standard SEO Optimization</li>
                  <li>Free Support</li>
                </ul>
                <Link className="btn btn-primary" to="/contact">Start Project</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">E-commerce Website</h5>
                <p className="card-text">$3000</p>
                <ul className="features-list">
                  <li>Custom Design & Layout</li>
                  <li>Unlimited Design Revision Cycle</li>
                  <li>Responsive HTML5/CSS3 Coding</li>
                  <li>Product Pages with Descriptions & Images</li>
                  <li>Shopping Cart Functionality</li>
                  <li>Payment Gateway Integration</li>
                  <li>User Account Creation</li>
                  <li>Order Management System</li>
                  <li>Standard SEO Optimization</li>
                  <li>Free Support</li>
                </ul>
                <Link className="btn btn-primary" to="/contact">Launch My Store</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
