import React from 'react';
import { Link } from 'react-router-dom';
import './Banner.css'; // Import your CSS file for styling

const Banner = () => {
  return (
    <section className="banner">
      <div className="common-bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 order-md-2">
            {/* Right Side Image (Replace 'path/to/image' with your image path) */}
            <img src="/images/banner.png" className="banner-image" alt="banner" />
          </div>
          <div className="col-md-6 col-sm-12 order-md-1">
            {/* Left Side Content */}
            <div className="banner-content">
              <h1 className="banner-heading">Crafting Digital Experiences</h1>
              <p className="banner-subheading">Transforming Ideas into Captivating Realities</p>
              <Link className="btn btn-primary" to="/contact">Get Started</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
