import React from 'react';
import { Link } from 'react-router-dom';
import './DevelopmentSection.css'; // Import your CSS file for styling

const DevelopmentSection = () => {
  return (
    <section className="development-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="development-image">
              {/* Replace 'path/to/image' with your image path */}
              <img src="/images/development.png" alt="Development" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="development-content">
              <h2>Innovative Solutions, <br/>Future-Ready Development</h2>
              <p>Stay ahead in the digital landscape with our Development Services leveraging Trending Technology. We thrive on implementing cutting-edge tools and frameworks to build robust and future-proof solutions. </p>
              <Link className="btn btn-primary" to="/contact">Get Started</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentSection;
