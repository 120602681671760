import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import './contact.css'; 
// GraphQL query to fetch form and its fields
const GET_CONTACT_FORM = gql`
  {
    form(id: "1", idType: DATABASE_ID) {
      title
      fields {
        nodes {
          fieldId
          label
          type
        }
      }
    }
  }
`;

// GraphQL mutation to submit form entry
const SUBMIT_FORM = gql`
  mutation SubmitForm($input: SubmitFormInput!) {
    submitForm(input: $input) {
      errors {
        fieldId
        message
        slug
      }
      success
    }
  }
`;

const countries = [
  "Web Design",
  "Web Development",
  "App Development",
  // Add more countries as needed
];

const ContactPage = () => {
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const { loading, error, data } = useQuery(GET_CONTACT_FORM);
  const [submitForm] = useMutation(SUBMIT_FORM);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const input = {
        formId: 1,
        data: Object.entries(formData).map(([id, value]) => ({ id: parseInt(id), value })),
      };

      const { data: submissionData } = await submitForm({ variables: { input } });

      if (submissionData.submitForm.success) {
        //console.log('Form submitted successfully!');
        setSuccessMessage('Form submitted successfully!'); // Set success message

        // Clear form data
        const clearedFormData = {};
        Object.keys(formData).forEach((key) => {
          clearedFormData[key] = '';
        });
        setFormData(clearedFormData);
        //console.log('Form data cleared:', clearedFormData);

        // Display success message for a few seconds (example: 3 seconds)
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        //console.error('Form submission failed:', submissionData.submitForm.errors);
        // Handle form submission errors
      }
    } catch (err) {
      //console.error('Error submitting form:', err);
      // Handle submission errors
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const formFields = data.form.fields.nodes;

  return (
    <div className="contact">
    <div className="container-fluid p-0">
      <div className="page-heading">
        <h1>Contact Us</h1>
      </div>
    </div>
    <div className="container mt-4">
      <form onSubmit={handleSubmit}>
      {formFields
  .filter((field) => field.type !== 'submit')
  .map((field) => (
    <div className="mb-3" key={field.fieldId}>
      <label htmlFor={field.fieldId} className="form-label">
        {field.label}
      </label>
      {field.type === 'listcountry' ? (
        <select
          className="form-select"
          id={field.fieldId}
          onChange={handleChange}
          value={formData[field.fieldId]} // Bind value to the state
          required
        >
          <option value="">Project Type</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
      ) : (
                <input
                  type={field.type === 'email' ? 'email' : 'text'}
                  className="form-control"
                  id={field.fieldId}
                  onChange={handleChange}
                  value={formData[field.fieldId]} // Ensure the value is bound to the state
                  required
                />
              )}
            </div>
          ))}
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
    </div>
  );
  };
  
  export default ContactPage;



