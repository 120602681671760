import React from 'react';
import { Link } from 'react-router-dom';
import './DesignSection.css'; // Import your CSS file for styling

const DesignSection = () => {
  return (
    <section className="design-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-1 order-2">
            <div className="design-content">
              <h2>Crafting Unique Digital Identities</h2>
              <p>Elevate your brand with our Creative Custom Theme Design services. We specialize in tailoring visually stunning and intuitive themes that resonate with your audience.</p>
              <Link className="btn btn-primary" to="/services">Learn More</Link>
            </div>
          </div>
          <div className="col-md-6 order-md-2 order-1">
            <div className="design-image">
              {/* Replace 'path/to/image' with your image path */}
              <img src="/images/design.png" alt="Design" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesignSection;
