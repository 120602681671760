import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Homepage from './pages/Homepage';
import ServicesPage from './pages/ServicesPage';
import BlogListPage from './pages/BlogListPage';
import SingleBlogPost from './pages/SingleBlogPost';
import GetStarted from './components/GetStarted/GetStarted';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/About';
// Import other pages as needed
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/services" element={<ServicesPage />} />
          <Route exact path="/portfolio" element={<PortfolioPage />} />
          <Route exact path="/blog" element={<BlogListPage />} />
          <Route path="/blog/:slug" element={<SingleBlogPost />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/about" element={<AboutPage />} />
        </Routes>
        <GetStarted />
        <Footer /> 
      </div>
    </Router>
  );
};

export default App;
