import React from 'react';
import './Services.css'; // Import Bootstrap and your CSS file for styling (create this file)
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Services = () => {
  return (
    <section className="services">
      <div className="container">
        <h2>Elevating Brands through Design Innovation</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="service-item">
              <i className="fas fa-paint-brush service-icon"></i>
              <h3>Creative Design</h3>
              <p>I specialize in crafting bespoke designs that fuse aesthetic appeal with functionality. My approach revolves around understanding your brand identity...</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item">
              <i className="fas fa-laptop-code service-icon"></i>
              <h3>Website Development</h3>
              <p>Being proficient in HTML5/CSS3, WordPress, WooCommerce, Shopify, and Squarespace, I offer comprehensive website development services...</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item">
              <i className="fab fa-react service-icon"></i>
              <h3>Applications Development</h3>
              <p>My expertise extends to building robust and responsive React applications, offering customized solutions that cater to your specific business requirements.</p>
            </div>
          </div>
        </div>
        <Link className="btn btn-primary" to="/contact">Get Started</Link>
      </div>
    </section>
  );
};

export default Services;
