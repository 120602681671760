import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Retrieve the JWT token from local storage
const token = localStorage.getItem('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5jcmVhdGlkZXYuY29tIiwiaWF0IjoxNzAyNTc3MjAyLCJuYmYiOjE3MDI1NzcyMDIsImV4cCI6MTcwMzE4MjAwMiwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiMSJ9fX0.jJI3Z4IjzHnS-MRLBtQlbyCfrLFSBUqHnCnbeCVU-X0');

// Set up the HTTP link for the GraphQL endpoint
const httpLink = createHttpLink({
  uri: 'https://api.creatidev.com/graphql', // Replace with your GraphQL endpoint
});

// Set up the authorization header with the JWT token
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '', // Include the token in the authorization header
    },
  };
});

// Create an Apollo Client instance with authorization headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
