import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import './BlogListPage.css';

const GET_ALL_POSTS = gql`
  {
    posts {
      nodes {
        id
        title
        excerpt
        date
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const BlogListPage = () => {
  const { loading, error, data } = useQuery(GET_ALL_POSTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const allPosts = data.posts.nodes;


  const blogPosts = allPosts.map((post) => {

    return (
      <div key={post.id} className="col-md-4 col-12 mb-4">
        <div className="blog-post">
          <div className="blog-thumbnail">
            <img
              src={post.featuredImage.node.sourceUrl}
              alt={post.title}
              className="img-fluid"
            />
          </div>
          <div className="blog-content">
            <div className="blog-title">
              <h3>{post.title}</h3>
            </div>
            <p>
              {new Date(post.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
            <p>
              {post.excerpt &&
                post.excerpt
                  .replace(/(<([^>]+)>)/gi, '')
                  .replace(/&hellip;/g, '...')
                  .replace(/&#8217;/g, "'")}
            </p>
            <Link to={`/blog/${post.slug}`} className="read-more-link">
              Read More
            </Link>
          </div>
        </div>
      </div>
    );
  });

  const rows = [];
  let row = [];
  for (let i = 0; i < blogPosts.length; i++) {
    if (i > 0 && i % 3 === 0) {
      rows.push(<div className="row" key={i}>{row}</div>);
      row = [];
    }
    row.push(blogPosts[i]);
  }
  if (row.length > 0) {
    rows.push(<div className="row" key="last-row">{row}</div>);
  }

  return (
    <div className="blog">
      <div className="container-fluid p-0">
        <div className="page-heading">
          <h1>Blog</h1>
        </div>
      </div>
      <div className="container blog-list">
        {rows}
      </div>
    </div>
  );
};

export default BlogListPage;
