import React from 'react';
import { Link } from 'react-router-dom';
import './GetStarted.css'; // Import your CSS file for styling

const GetStarted = () => {
  return (
    <section className="get-started" style={{ backgroundColor: '#0c004d' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Get Started With Creatidev Today</h2>
            <p>Ready to embark on your digital journey? Let's collaborate and bring your vision to life! </p>
            <Link className="btn btn-primary" to="/contact">Get Started</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
