import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import './RecentBlog.css'; // Import Bootstrap and your CSS file for styling (create this file)

// GraphQL query to fetch recent blog post data
const GET_RECENT_POSTS = gql`
  {
    posts(first: 3) {
      nodes {
        id
        title
        date
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const RecentBlog = () => {
  const { loading, error, data } = useQuery(GET_RECENT_POSTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const recentPosts = data.posts.nodes;

  return (
    <section className="recent-blog">
      <div className="container">
        <h2 className="section-title">Recent Blog</h2>
        <div className="row">
          {recentPosts.map(post => (
            <div className="col-md-4" key={post.id}>
              <div className="blog-post">
              <Link to={`/blog/${post.slug}`} className="blog-link">
                  <img src={post.featuredImage.node.sourceUrl} alt={post.title} className="blog-image" />
                  <h3>{post.title}</h3>
                  <p>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RecentBlog;
