import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import './SingleBlogPost.css';
import { Helmet } from 'react-helmet';

const GET_SINGLE_POST = gql`
  query GetSinglePost($slug: String!) {
    postBy(slug: $slug) {
      id: databaseId
      title
      content
      date
      featuredImage {
        node {
          sourceUrl
        }
      }
      author {
        node {
          name
        }
      }
      excerpt
    }
  }
`;
const sanitizeHTML = (html) => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || '';
};

const SingleBlogPost = () => {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(GET_SINGLE_POST, {
    variables: { slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const post = data.postBy;

  return (
    <div className="single-blog-post">
<Helmet>
  <meta prefix="og: http://ogp.me/ns#" property="og:title" content={sanitizeHTML(post.title || 'Default title')} />
  {post.featuredImage && (
    <meta prefix="og: http://ogp.me/ns#" property="og:image" content={post.featuredImage.node.sourceUrl} />
  )}
  <meta prefix="og: http://ogp.me/ns#" property="og:description" content={sanitizeHTML(post.excerpt || 'Default description')} />
  {post.author && (
    <meta prefix="article: http://ogp.me/ns/article#" property="article:author" content={post.author.node.name} />
  )}
  {post.date && (
    <meta prefix="article: http://ogp.me/ns/article#" property="article:published_time" content={post.date} />
  )}
</Helmet>
      <div className="container">
        <h2>{post.title}</h2>
        <p>
          {new Date(post.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </p>
        {post.featuredImage && (
          <img src={post.featuredImage.node.sourceUrl} alt={post.title} className="blog-image" />
        )}
        <div
          dangerouslySetInnerHTML={{ __html: post.content }}
          className="blog-content"
        />
      </div>
    </div>
  );
};

export default SingleBlogPost;
