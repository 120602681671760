import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client'; // Import necessary dependencies from Apollo Client
import Banner from '../components/Banner/Banner';
import Services from '../components/Services/Service';
import DesignSection from '../components/Design/DesignSection';
import DevelopmentSection from '../components/Development/DevelopmentSection';
import RecentBlog from '../components/Blog/RecentBlog';
import Pricing from '../components/Pricing/Pricing';

const Homepage = () => {
  const [seoData, setSeoData] = useState(null);

  const GET_SEO_DATA = gql`
    {
      pageBy(uri: "home") {
        seo {
          title
          metaKeywords
          metaDesc
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_SEO_DATA);

  useEffect(() => {
    if (data && data.pageBy && data.pageBy.seo) {
      setSeoData(data.pageBy.seo);
    }
  }, [data]);

  return (
    <main>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error fetching data</p>
      ) : (
        <>
          {seoData && (
            <Helmet>
              <title>{seoData.title}</title>
              <meta name="description" content={seoData.metaDesc || 'Your default meta description'} />
              {seoData.metaKeywords && <meta name="keywords" content={seoData.metaKeywords} />}
              {/* Add other SEO-related tags using Helmet */}
            </Helmet>
          )}

      {/* Your Homepage content */}
      <Banner />
      <Services />
      <DesignSection />
      <DevelopmentSection />
      <Pricing />
      <RecentBlog />
      </>
      )}
    </main>
  );
};

export default Homepage;
