import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import your CSS file for styling (create this file)

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            {/* First Column for Logo */}
            <div className="footer-column">
              <img src="/images/logo.png" alt="Logo" className="footer-logo" />
            </div>
          </div>
          <div className="col-md-3">
            {/* Second Column for Pages links */}
            <div className="footer-column">
              <h5>Quick Links</h5>
              <ul className="footer-links">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/about">About</Link></li>
                {/* Add more pages links as needed */}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            {/* Third Column for Pages links */}
            <div className="footer-column">
              <h5>Company</h5>
              <ul className="footer-links">
                <li><Link to="/portfolio">Portfolio</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                {/* Add more pages links as needed */}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            {/* Fourth Column for Contact details */}
            <div className="footer-column">
              <h5>Reach Out for Your Digital Solutions</h5>
              <a href="mailto:info@creatidev.com">info@creatidev.com</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 copyright">
            {/* Common copyright text */}
            <p className="text-center">&copy; 2024 Creatidev. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
